import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { scale, rhythm } from "../utils/typography"
import {
  Header,
  EtudesForTheWebBrowserHeaderNoLink,
} from "../components/Header"
import { Footer } from "../components/Footer"
import { SEO } from "../components/Seo"
import { JasonWithText } from "../components/Jason"
import theme from "../utils/theme"
import { useMediaQuery } from "react-responsive"
import tocbot from "tocbot"
import "tocbot/dist/tocbot.css"

const BodyFontFamily = theme.bodyFontFamily
  .map((font, i) =>
    i !== theme.bodyFontFamily.length - 1 ? `'${font}'` : font
  )
  .join(", ")

export const pageQuery = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM Do, YYYY")
        description
        twitterImageUrl
        tags
        chapter
        readingTime
        showTableOfContents
      }
    }
  }
`

// const GRAY_BACKGROUND = "rgba(243,247,249,1)"
const AVATAR_BACKGROUND = "#ddd"

const Template = props => {
  const {
    description,
    title,
    subtitle,
    date,
    twitterImageUrl,
    tags,
    chapter,
    readingTime,
    showTableOfContents
  } = props.data.mdx.frontmatter

  const isEtudesForTheWebBrowser = tags === "etudes-for-the-web-browser"
  const isWideEnoughForTableOfContents = useMediaQuery({ minWidth: 1420 })
  const shouldShowTableOfContents = isWideEnoughForTableOfContents && showTableOfContents

  React.useEffect(() => {
    if (shouldShowTableOfContents) {
      tocbot.init({
        // Where to render the table of contents.
        tocSelector: ".js-toc",

        // Where to grab the headings to build the table of contents.
        contentSelector: ".IndexPageContent",

        // Which headings to grab inside of the contentSelector element.
        headingSelector: "h1,h2,h3",

        // For headings inside relative or absolute positioned containers within content.
        hasInnerContainers: true,

        // Headings to ignore.
        ignoreSelector: ".ClickableCardHeading,.TocbotIgnore",
      })
    }
  }, [shouldShowTableOfContents])

  return (
    <>
      <SEO
        description={description}
        imageUrl={twitterImageUrl}
        imageAlt={title}
        title={title}
      />
      <div
        style={{
          position: "fixed",
          right: 10,
          top: 155,
        }}
      >
        <div className="js-toc" />
      </div>
      <Header />
      <main
        className="IndexPageContent"
        style={{
          margin: "0 auto",
          paddingRight: rhythm(1 / 2),
          paddingLeft: rhythm(1 / 2),
          maxWidth: 600,
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isEtudesForTheWebBrowser && (
            <EtudesForTheWebBrowserHeaderNoLink
              avatarStyleProps={{ backgroundColor: AVATAR_BACKGROUND }}
              noBorder
              chapter={chapter}
            />
          )}
        </div>
        <h1
          className="TocbotIgnore"
          style={{
            marginBottom: rhythm(1 / 4),
            ...(isEtudesForTheWebBrowser ? { marginTop: rhythm(1 / 2) } : {}),
          }}
        >
          {title}
        </h1>
        <div
          style={{
            fontWeight: 300,
            fontStyle: "italic",
            fontFamily: BodyFontFamily,
            marginBottom: rhythm(0.25),
            ...scale(1 / 4),
          }}
        >
          {subtitle}
        </div>
        <div
          style={{
            fontStyle: "italic",
            fontSize: "0.8rem",
          }}
        >
          Published {date}{readingTime ? <>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;{readingTime} min read</> : null}
        </div>
        {!isEtudesForTheWebBrowser && <JasonWithText />}
        <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
      </main>
      <Footer />
    </>
  )
}

export default Template
